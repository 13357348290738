<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 第一行 -->
      <el-row>
        <el-button
          type="primary"
          @click="getAddGoods"
        >+ 新增标准商品</el-button>
      </el-row>
      <!-- 搜索与添加区 -->
      <div class="secondrow">
        <!-- 根据商品名查询 -->

        <div class="input">
          商品名称：
          <el-input
            placeholder="请输入查询的商品名"
            v-model="queryInfo.name"
            clearable
            @clear="getGoodsList"
            @change="searchGoodsList"
          >
            <el-button
              slot="append"
              @click="searchGoodsList"
            >搜索</el-button>
          </el-input>
        </div>

        <!-- 根据商品类别查询 -->

        <div class="input">
          所属分类：
          <el-select
            v-model="goodsclassId"
            clearable
            @clear="getGoodsList"
            placeholder="请选择商品分类查询"
            @change="searchGoodsList"
          >
            <el-option
              v-for="item in productClass"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>

        <!-- 计价方式 -->

        <div class="input">
          计价方式：
          <el-select
            v-model="queryInfo.feeType"
            clearable
            @clear="getGoodsList"
            placeholder="请选择计价方式"
            @change="searchGoodsList"
          >
            <el-option
              v-for="item in feeTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <el-table
        :data="goodsList"
        style="width: 100%"
        stripe
        border
      >
        <el-table-column
          prop="id"
          label="id"
          width="100"
        > </el-table-column>
        <el-table-column
          prop="icon"
          label="商品图片"
        >
          <!-- 市场logo -->
          <template slot-scope="scope">
            <el-popover
              trigger="hover"
              title=""
              placement="bottom"
              v-if="scope.row.icon"
            >
              <img
                :src="scope.row.icon"
                width="200"
                height="200"
              />
              <img
                slot="reference"
                :src="scope.row.icon"
                style="height: 80px;width: 80px"
              />
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column
          prop="name"
          label="商品名称"
        > </el-table-column>
        <el-table-column
          prop="categoryVo.name"
          label="所属分类"
        >
        </el-table-column>
        <!-- <el-table-column prop="newFeeType" label="计价类型" width="120">
          <template slot-scope="scope">
            <el-tag type="primary"
                    v-if="scope.row.feeType === 0">称重</el-tag>
            <el-tag type="success"
                    v-else-if="scope.row.feeType == 1">计件</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="categoryVo.name"
                         label="所属分类">
        </el-table-column>
        <el-table-column prop="newPrice"
                         label="建议单价(元)"
                         width="120">
        </el-table-column> -->

        <!-- <el-table-column prop="newPrice"
                         label="价格(分)"
                         width="120">
        </el-table-column> -->
        <el-table-column
          prop="newPrice"
          label="建议单价(元)"
        >
          <template slot-scope="scope">
            <div
              type="primary"
              v-if="scope.row.feeType === 0"
            >
              ￥{{ scope.row.price / 100 }}/kg
            </div>
            <div
              type="success"
              v-else-if="scope.row.feeType == 1"
            >
              ￥{{ scope.row.price / 100 }}/份
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="editProduct(scope.row)"
            >修改</el-button>
            <el-button
              type="danger"
              size="mini"
              @click="deleteGoods(scope.row.id)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        :total="total"
        @size-change="handleSizeChange"
        :current-page="queryInfo.pageNo"
        :page-size="queryInfo.limit"
        :page-sizes="[15, 30, 50, 100]"
        @current-change="handleCurrentChange"
        layout="total,sizes,prev,pager,next,jumper"
      ></el-pagination>
    </el-card>
    <!-- 添加对话框 -->
    <el-dialog
      title="新增商品"
      :visible.sync="AddDialogVisible"
      width="50%"
      @close="AddDialogClosed"
    >
      <!-- 内容主体区域 -->
      <el-form
        ref="AddFormRef"
        :model="AddProductForm"
        :rules="AddFormRules"
        label-width="100px"
      >
        <el-form-item
          label="商品名称"
          prop="name"
        >
          <el-input
            v-model="AddProductForm.name"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="商品分类"
          prop="categoryId"
        >
          <el-select
            v-model="AddProductForm.categoryId"
            clearable
            placeholder="请选择商品分类"
          >
            <el-option
              v-for="item in productClass"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="商品图片"
          prop="icon"
          ref="file_Rule"
        >
          <!-- 添加图片 -->
          <el-upload
            action="/api/v1/file/file"
            :data="this.bizType1"
            :before-upload="beforeUpload"
            :on-success="handlesuccess1"
            :show-file-list="false"
          >
            <img
              v-if="imageUrl.marketLogo"
              :src="imageUrl.marketLogo"
              class="image2"
            />
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
            ></i>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="计价类型"
          prop="feeType"
        >
          <el-radio
            v-model="AddProductForm.feeType"
            label="0"
          >称重（kg）</el-radio>
          <el-radio
            v-model="AddProductForm.feeType"
            label="1"
          >计件（份）</el-radio>
        </el-form-item>
        <el-form-item
          label="价格(元)"
          prop="price"
        >
          <el-input
            v-model="oldPrice"
            @change="changePrice"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="AddDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="getAddProduct"
        >确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改对话框 -->
    <el-dialog
      title="修改商品"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <!-- 内容主体区域 -->
      <el-form
        ref="editFormRef"
        :model="editProductForm"
        :rules="editFormRules"
        label-width="100px"
      >
        <el-form-item
          label="商品名称"
          prop="name"
        >
          <el-input
            v-model="editProductForm.name"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="商品分类"
          prop="categoryId"
        >
          <el-select
            v-model="editProductForm.categoryId"
            clearable
            placeholder="请选择商品分类"
          >
            <el-option
              v-for="item in productClass"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="商品图标"
          prop="icon"
        >
          <el-upload
            action="/api/v1/file/file"
            :data="this.bizType1"
            :before-upload="beforeUpload"
            :on-success="handlesuccess1"
            :show-file-list="false"
          >
            <img
              v-if="imageUrl.marketLogo"
              :src="imageUrl.marketLogo"
              class="image2"
            />
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
            ></i>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="计价类型"
          prop="feeType"
        >
          <el-radio
            v-model="editProductForm.feeType"
            label="0"
          >称重（kg）</el-radio>
          <el-radio
            v-model="editProductForm.feeType"
            label="1"
          >计件（份）</el-radio>
        </el-form-item>
        <el-form-item
          label="价格(元)"
          prop="price"
        >
          <el-input
            v-model="oldPrice2"
            clearable
            @change="changePrice2"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="getEditProduct"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      goodsclassId: '', // 选中的类别id
      // 根据商品分类查询出列表
      productClass: [],
      // 列表的参数
      queryInfo: {
        limit: 15,
        pageNo: 1,
        name: '',
        categoryId: '',
        feeType: '' // 计价类型 0-称重 1-计件
      },
      // 计价列表
      feeTypeList: [
        {
          id: '0',
          name: '称重'
        },
        {
          id: '1',
          name: '计件'
        }
      ],
      goodsList: [], // 商品列表
      total: 0,
      // 修改对话框的隐藏的与显示
      editDialogVisible: false,
      editProductForm: {
        categoryId: '',
        name: '',
        icon: 'logo',
        feeType: '',
        price: ''
      },
      editFormRules: {
        categoryId: [
          { required: true, message: '请选择商品分类', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
        icon: [{ required: true, message: '请输入商品图片', trigger: 'blur' }],
        feeType: [
          { required: true, message: '请输入计价类型', trigger: 'blur' }
        ],
        price: [{ required: true, message: '请输入价格', trigger: 'blur' }]
      },
      // 修改商品的id
      editId: '',
      // 添加对话框的隐藏与显示
      AddDialogVisible: false,
      // 添加对话框的form
      AddProductForm: {
        categoryId: '',
        name: '',
        icon: '',
        feeType: '',
        price: ''
      },
      // 绑定的价格，需要除以100
      oldPrice: '',
      oldPrice2: '',
      // 添加对话框的校验规则
      AddFormRules: {
        categoryId: [
          { required: true, message: '请选择商品分类', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
        icon: [{ required: true, message: '请输入商品图片', trigger: 'blur' }],
        feeType: [
          { required: true, message: '请输入计价类型', trigger: 'blur' }
        ],
        price: [{ required: true, message: '请输入价格', trigger: 'blur' }]
      },
      bizType1: {
        bizType: 'goodsStyle'
      },
      imageUrl: {
        marketLogo: ''
      },
      edit: '' // 修改对话框的scope内容
    }
  },
  created () {
    this.getGoodsList()
    this.getProductClass()
  },
  methods: {
    searchGoodsList () {
      this.queryInfo.pageNo = 1
      this.getGoodsList()
    },
    // 商品列表
    async getGoodsList () {
      this.queryInfo.categoryId = this.goodsclassId
      const { data: res } = await this.$http.get('productStandard/query', {
        params: this.queryInfo
      })
      this.goodsList = res.data.data.map(item => ({
        ...item,
        newFeeType: item.feeType === 0 ? '称重' : '计价',
        // newPrice: item.price / 100
        newPrice: item.price
      }))
      this.total = res.data.total
      // console.log(this.goodsList, 11122)
    },
    // 根据分类查询到商品分类的列表
    async getProductClass () {
      const res = await this.$http.get(
        'productCategory/list?pageNo=1&limit=2000'
      )
      this.productClass = res.data.data.data
      // this.goodsclass = res.data.data
      // console.log(this.productclass)
    },
    // 监听 limit 改变的事件
    handleSizeChange (newSize) {
      this.queryInfo.limit = newSize
      this.getGoodsList()
    },
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getGoodsList()
    },
    // 监听修改价格
    changePrice () {
      this.AddProductForm.price = this.oldPrice * 100
    },
    // 监听修改价格
    changePrice2 () {
      this.editProductForm.price = this.oldPrice2 * 100
    },
    // 修改商品
    editProduct (edit) {
      this.editId = edit.id
      this.editProductForm.categoryId = edit.categoryVo.id
      this.editProductForm.name = edit.name
      this.editProductForm.feeType = edit.feeType.toString()
      this.editProductForm.price = edit.price
      this.imageUrl.marketLogo = edit.icon
      this.oldPrice2 = this.editProductForm.price / 100
      // console.log(edit)
      this.editDialogVisible = true
    },
    getEditProduct () {
      this.editProductForm.icon = this.imageUrl.marketLogo
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        // this.editProductForm.categoryId = editProductForm.categoryId
        // this.editProductForm.price = this.editProductForm.price * 100

        const res = await this.$http.put(
          `/productStandard/${this.editId}`,
          this.editProductForm
        )
        // console.log(res)
        this.getGoodsList()
        if (res.data.code !== 0) return this.$message.error(res.data.message)
        this.$message.success('修改商品成功')
        this.editDialogVisible = false
      })
    },
    // 监听修改对话框的清空
    editDialogClosed () {
      this.$refs.editFormRef.resetFields()
      this.imageUrl.marketLogo = ''
      this.oldPrice2 = ''
    },
    // 添加商品
    getAddGoods () {
      this.AddDialogVisible = true
    },
    getAddProduct () {
      this.AddProductForm.icon = this.imageUrl.marketLogo
      this.$refs.AddFormRef.validate(async valid => {
        if (!valid) return
        // this.AddProductForm.price = this.AddProductForm.price * 100

        const res = await this.$http.post(
          'productStandard/',
          this.AddProductForm
        )
        // console.log(res)
        this.getGoodsList()
        if (res.data.code !== 0) return this.$message.error(res.data.message)
        this.$message.success('添加商品成功')
        this.AddDialogVisible = false
      })
    },
    // 监听添加对话框的清空
    AddDialogClosed () {
      this.$refs.AddFormRef.resetFields()
      this.imageUrl.marketLogo = ''
      this.oldPrice = ''
    },
    // 删除商品
    async deleteGoods (id) {
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除商品，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(`/productStandard/${id}`)
      if (res.code !== 0) {
        return this.$message.error('删除失败')
      }
      this.$message.success('删除成功')
      this.getGoodsList()
    },
    beforeUpload (file) {
      const types = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp']
      const isImage = types.includes(file.type)
      if (!isImage) {
        this.$message.error('上传图片只能是jpg、jpeg、bmp、png格式')
        return false
      }
      return true
    },
    // 监听图片上传成功的钩子
    handlesuccess1 (response, file, fileList) {
      console.log(response)
      this.imageUrl.marketLogo = response.data
      this.$refs.file_Rule.clearValidate() // 取消该项校验
      // console.log(response.data, 11)
    }
    // // 订单商品列表
    // getOrderDetail(id) {
    //   this.$router.push(`/orderGoods/${id}`)
    // }
  }
}
</script>

<style lang="less" scoped>
.el-row {
  margin-bottom: 20px;
}
.logoImg {
  width: 80px;
  height: 80px;
}
.image2 {
  width: 80px;
  height: 80px;
}
// img {
//   width: 80px;
//   height: 80px;
//   border-color: #000;
// }
.input {
  display: flex;
  width: 340px;
  align-items: center;
  justify-content: center;
  margin-right: -20px;
}
.input:last-child {
  margin-left: -40px;
}
.el-select {
  width: 180px;
}
.el-input {
  width: 250px;
}
.secondrow {
  display: flex;
}
</style>
